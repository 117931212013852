import Vue from "vue";
import App from "./App.vue";
// import store from './store';
import router from "./router";
import Mixin from "./mixins/index";
Vue.mixin(Mixin);
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// import VueClipboard from 'vue-clipboard2'
import http from "@/axios-config";

import {IconsPlugin } from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
const options = {
  // You can set your default options here
};
// Vue.use(BootstrapVue)
Vue.use(Toast, options);
router.beforeEach((to, from, next) => {
  let isLoggedIn =
    JSON.parse(localStorage.getItem("authInfo")) &&
    JSON.parse(localStorage.getItem("authInfo"))[0].authToken;
  if (to.meta.requireAuth) {
    if (isLoggedIn) {
      window.document.title = to.meta && to.meta.title ? to.meta.title : 'Home';
      next();
     
    } else {
      next("/login");
    }
  }
  return next();
});

Vue.config.productionTip = false;
Vue.prototype.$api = http;

new Vue({
  // store,
  router,
  render: (h) => h(App),
}).$mount("#app");
