<template>
  <layout>
    <div class="page-breadcrumb">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
        <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
      </div>
    </div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <div class="col-12 text-center h1 mb-3 fw-400">
          Create <span class="text-blue">Content</span>
        </div>
        <div class="row align-items-center">
          <VueElementLoading
            :active="loading"
            spinner="ring"
            color="#42BFC7"
            text="Fetching content please wait..."
          />
          <div class="col-1 offset-2 my-3">
            <label class="fw-400" for="key">Keyword</label>
          </div>
          <div class="col-8">
            <input
              type="text"
              v-model="form.keyword"
              class="form-control"
              placeholder="Enter your keyword e.g weight loss"
              id="key"
            />
          </div>
          <div class="col-1 offset-2 my-3">
            <label class="fw-400" for="language">Language</label>
          </div>
          <div class="col-8">
            <select
              name=""
              id="language"
              class="select-lang"
              v-model="form.lang"
            >
              <option value="en" selected="selected">English</option>
              <option value="fr">French</option>
              <option value="de">Germany</option>
              <option value="es">Spanish</option>
              <option value="nl">Netherland</option>
              <option value="cn">Chinese</option>
              <option value="jp">Japanese</option>
              <option value="br">Brazil</option>
              <option value="ru">Russia</option>
              <option value="se">Sweden</option>
              <option value="pl">Polish</option>
              <option value="kr">Korean</option>
              <option value="tr">Turkish</option>
            </select>
          </div>
          <div class="col-1 offset-2 my-3">
            <label class="fw-400" for="rewrite">Rewrite Articles</label>
          </div>
          <div class="col-8">
            <select name="" id="rewrite" v-model="form.rewrite">
              <option value="original">keep original</option>
              <option value="unique">make unique</option>
            </select>
          </div>
          <div class="col-1 offset-2 my-3">
            <label class="fw-400" for="contents">How Many Contents</label>
          </div>
          <div class="col-8" v-if="pack > 1">
            <select name="" id="contents" v-model="form.numbers">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
          <div class="col-8" v-else>
            <select name="" id="contents" v-model="form.numbers">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div class="col-1 offset-2"></div>
          <div class="col-8">
            <button @click.prevent="createContent" class="btn btn-blue">
              Check
            </button>
          </div>
        </div>
        <div class="row">
          <!-- Column -->
          <!-- <div class="col-12 text-center h1 mb-3 fw-400">Settings</div> -->
          <nav class="tabs mb-4 align-items-center mt-4 center-it">
            <button
              class="tabs__item tabs__item_active"
              @click="handleClick(`tab${i + 1}`)"
              v-for="(t, i) in tabs"
              :key="i"
            >
              {{ t.title }}
            </button>
            <!-- active tab -->
          </nav>
          <div class="font-weight-bold" v-for="(t, i) in contents" :key="i">
            <div v-if="currentTab == 'tab' + (i + 1)">
              <!-- <div class="12 text-dark fw-400">Update your personal details</div> -->
              <div class="row">
                <div class="col-md-12">
                  <h1>{{ t.title }}</h1>
                  <p v-html="t.description" style="font-weight: normal"></p>
                </div>
              </div>

              <!-- <div class="col-12 text-success fw-400">
            Congratulations Your API is now activated, you can now use the Golden
            keyword tool !
          </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-12">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                      <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
                      <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <p></p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Et ullam, eum repudiandae blanditiis possimus sit minima autem itaque laudantium officia odio doloribus, numquam odit suscipit sed quos unde molestiae impedit.
                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <p></p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus praesentium quidem commodi earum! Asperiores id iure dolores ut expedita consequuntur repudiandae accusamus quod autem repellat, inventore deserunt itaque perspiciatis distinctio!
                    </div>
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <p></p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sunt cumque accusamus corporis sapiente, maiores qui quaerat neque, laboriosam nihil, libero pariatur nostrum. Ut sit veniam nihil facilis beatae laborum et!
                    </div>
                  </div>
            </div> -->
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
  </layout>
</template>
<style scoped>
.tabs {
  position: relative;
  margin: 0 auto;
}

.tabs__item {
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  padding-bottom: 8px;
  font-size: 13px;
  letter-spacing: 0.8px;
  color: gray;
  /* background: #42bfc7; */
  /* text-decoration: underline #42bfc7 !important; */
  text-decoration: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
  width: 12%;
}

.tabs__item_active {
  color: black;
}

.tabs__item:hover {
  /* border-bottom: 2px solid gray; */
  color: black;
}

.tabs__item:focus {
  outline: none;
  /* border-bottom: 2px solid gray; */
  color: white;
  border-bottom: 2px solid #42bfc7 !important;
  border-radius: 10px;
}

.tabs__item:first-child {
  margin-left: 0;
}

.tabs__item:last-child {
  margin-right: 0;
}

.tabs__active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: black;
  transition: transform 0.4s ease, width 0.4s ease;
}
/* .tabs__item_active {
    background: none !important;
  border-bottom: 2px solid #42bfc7 !important;

} */
.center-it {
  /* align-items: center; */
  /* text-align: center; */
}
</style>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    layout,
    axios,
    VueElementLoading,
  },
  data() {
    return {
      form: {
        pack: "",
      },
      contents: {},
      tabs: [],
      loading: false,
      currentTab: "tab1",
      pack: "",
    };
  },
  methods: {
    createContent() {
      this.loading = true;
      this.tabs = [];
      this.$api
        .post(this.dynamic_route("/content/get-content"), this.form)
        .then((res) => {
          this.loading = false;
          // console.log(res);
          this.contents = res.data;
          let item = res.data;
          let arr = [];
          console.log(item.length);

          for (let index = 0; index < item.length; index++) {
            var data = {
              title: "Content " + (index + 1),
              value: "tab" + (index + 1),
            };
            this.tabs.push(data);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
        });
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
  },

  mounted() {
    this.pack = JSON.parse(localStorage.getItem("authInfo"))[0].perm[0].package;
    this.form.pack = JSON.parse(localStorage.getItem("authInfo"))[0].perm[0].package;
  },
};
</script>