import axios from "axios";

let token =
  localStorage.getItem("authInfo") &&
  JSON.parse(localStorage.getItem("authInfo"))[0].authToken;

const appAxios = axios.create({
  headers: {
    authorization: `Bearer ${token}`,
  },
});

export const http = {
  get(url, data = {}, configs = {}) {
    return appAxios.get(url, {
      ...configs,
      params: data,
    });
  },
  post(url, data = {}, configs = {}) {
    return appAxios.post(url, data, configs);
  },

  put(url, data = {}, configs = {}) {
    return appAxios.put(url, data, configs);
  },

  delete(url, data = {}, configs = {}) {
    return appAxios.delete(url, data, configs);
  },
};

const onErrorResponse = async (error) => {
  if (error.response) {
    if (
      error.response.status === 401 &&
      error.response.data.message === "Unauthenticated."
    ) {
      localStorage.removeItem("authInfo");
      localStorage.removeItem("auth_user");
      this.$route.push("/login");
      this.$toast.error("Login expired", {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    } else {
      return error.response;
    }
  }

  return error;
};

const onSuccessResponse = (response) => {
  return response;
};

appAxios.interceptors.response.use(onSuccessResponse, onErrorResponse);

export default appAxios;
