<template>
    <layout>
     <!-- ============================================================== -->
        <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
          </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->
         <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Sales chart -->
          <!-- ============================================================== -->
          <div class="row">
            <!-- Column -->
            <div class="col-12 text-center h1 mb-3 fw-400">
                Packages   <span class="text-blue">Permissions</span>
            </div>
            <div class="col-12">
              <div class=" text-center">
                  <div class="row">
                      <table class="table table-bordered ">
                          <thead>
                              <td>#</td>
                              <td>Role</td>
                              <td>Descriptiion</td>
                              <td>Action</td>
                          </thead>
                        <tbody>
                             <tr>
                            <td>1</td>
                            <td>Administrator</td>
                            <td>Add all modules and functions</td>
                            <td>
                                <div class="dropdown">
                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa fa-th"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <b-button v-b-modal.modal data-bs-toggle="modal" data-bs-target="#exampleModal" class="dropdown-item"><i class="fa fa-info-circle"></i> Attach Permissions</b-button>
                                    <b-modal id="modal" title="Attach Permission To Administrator Role">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Name Filter</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                                        </div>
                                        <div class="row mt-5">
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                </div>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                </div>
                                            </div>
                                             <div class="col-md-4">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                                    <label class="form-check-label" for="exampleCheck1">Check me out</label>
                                                </div>
                                            </div>
                                        </div>

                                    </b-modal>
                                    <b-button v-b-modal.modal-tall data-bs-toggle="modal" data-bs-target="#View" class="dropdown-item"><i class="fa fa-info-circle"></i> View Permissions</b-button>
                                    <b-modal id="modal-tall" title="View Permission attached To Administrator Role">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">Name Filter</span>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                                        </div>
                                        <div class="mt-5">
                                            <table class="table table-bordered">
                                                <tbody>
                                                 <tr>
                                                   <td>oer</td>
                                                </tr>
                                                <tr>
                                                   <td><div style="background-color:green; "></div> oer</td>
                                                </tr>
                                                <tr>
                                                   <td>oer</td>
                                                </tr>
                                                <tr>
                                                   <td>oer</td>
                                                </tr>
                                                <tr>
                                                   <td>oer</td>
                                                </tr>
                                                <tr>
                                                   <td>oer</td>
                                                </tr>
                                                </tbody>
                                               
                                            </table>
                                        </div>
                                    </b-modal>
                                </ul>
                                </div>
                            </td>
                             </tr>
                        </tbody>      
                       </table>
                  </div>
              </div>
            </div>
           
          </div>
         
          <!-- ============================================================== -->
          <!-- Recent blogss -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
    </layout>    
</template>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
import { BButton, BModal } from "bootstrap-vue";
export default {
    components: {
        layout,
        BButton,
        BModal
        }
}
</script>

<style scoped>
    table{
        font-size: 17px;
        text-align: left;
  
    }
    .input-group-text{
    display: flex;
    align-items: center;
    padding: 0.55rem 0.55rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    }
</style>




