<template>
    <layout>
         <!-- Bread crumb and right sidebar toggle -->
        <!-- ============================================================== -->
        <div class="page-breadcrumb">
          <div class="row align-items-center">
            <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
          </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Bread crumb and right sidebar toggle -->  
         <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Sales chart -->
          <!-- ============================================================== -->
          <div class="row">
            <!-- Column -->
            <div class="col-12 text-center h1 mb-3 fw-400">
                    Support Center
            </div>
            <div class="col-12 my-4 border p-4">
              <p>Thank You for your interest in Rank vault</p>
              <p>We know you are excited to try it out. if you need any assistance we are here to help you</p>
              <p>Please try sending only one email with all your questions. Multiple email from your will delay response! </p>
              <p>Please sen your support queries to : </p>
              <p class="text-success fw-400">
                  support@rankvault.online
              </p>
              <p>We generally respond with in a few hours, But please allow 24 hours for your question to be answered </p>
              <p>Please be patient we will get to you as soon as we can ... office is closed on weekends and holidays </p>
              <p>We loog forward to helping you achieve great result with rank vault  😀</p>
              <p>To your success!</p>
            </div>
          </div>
         
          <!-- ============================================================== -->
          <!-- Recent blogss -->
          <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->  
    </layout>   
</template>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
export default {
    components: {
        layout
        }
}
</script>