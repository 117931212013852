<template>
  <div>
    <div class="page-wrapper">
      <div class="wrapper">
        <div class="inner-div row">
          <div
            class="col-lg-6 col-md-6 col-12 bg-light-grey d-none d-md-block d-lg-block"
             style="height:100% ;"
          >
            <img
              src="assets/images/Rankvault.png"
              alt=""
              class="w-100"
              style="height: 100%"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-12 bg-light h-100 p-md-5 p-3">
            <div class="text-center text-blue mb-5">
              <span class="fas fa-user-circle fa-3x"></span>
              <p class="upper-text my-2">Create Account</p>
            </div>
            <form action="" @submit.prevent="register()">
              <div class="row">
                <div class="col-12">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text px-4" id="basic-addon1">
                        <span class="far fa-user-circle fa-2x"></span>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      v-model="form.username"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text px-4" id="basic-addon1">
                        <span class="far fa-envelope fa-2x"></span>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      v-model="form.email"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text px-4" id="basic-addon1">
                        <span class="fas fa-lock fa-2x"></span>
                      </span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                      v-model="form.password"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-blue btn-block w-100">SIGN UP</button>
                </div>
                <div class="col-12 text-right mt-2" style="text-align: right">
                  <router-link to="/login" class="forgot-text">
                    <span class="text-blue"></span> Back to login
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
  </div>
</template>
<script>
import axios from "axios";
export default {
//   components: {  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    register() {
      this.$api.post(this.dynamic_route("/auth/register"), this.form)
      .then((res) => {
                // window.location.href = "/home"
                this.form = {}
                 this.$toast.success("Success")

            this.$router.push({name: 'Manage Users'})
      })
      .catch((err) => {
          console.log()
           
            Object.values(err.response.data).forEach(element => {
                 this.$toast.error(element[0])
            });
        });
    },
  },
};
</script>
