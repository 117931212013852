<template>
  <div>
    <div class="page-wrapper">
      <div class="wrapper">
        <div class="inner-div row">
          <div
            class="
              col-lg-6 col-md-6 col-12
              bg-light-grey
              d-none d-md-block d-lg-block
            "
            style="height: 100%"
          >
            <img
              src="/assets/images/Rankvault.png"
              alt=""
              class="w-100"
              style="height: 100%"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-12 bg-light h-100 p-md-5 p-3">
            <div class="text-center text-blue mb-5">
              <span class="fas fa-user-circle fa-3x"></span>
              <p class="upper-text my-2">Reset Password</p>
            </div>
            <form action="" @submit.prevent="resetPassword">
              <div class="row">
                <div class="col-12">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text px-4" id="basic-addon1">
                        <span class="fas fa-lock fa-2x"></span>
                      </span>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                      placeholder="Enter your new password"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text px-4" id="basic-addon1">
                        <span class="fas fa-lock fa-2x"></span>
                      </span>
                    </div>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password_confirmation"
                      placeholder="Confirm your password"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div class="col-12">
                  <button class="btn btn-blue btn-block w-100">Change Password</button>
                </div>
                <div class="col-12 text-right mt-2" style="text-align: right">
                  <router-link :to="'/login'" class="forgot-text">
                    <span class="text-blue"></span> Back to login
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        token: this.$route.params.token
      },
      loading:false,
    };
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.$api
        .post(this.dynamic_route("/auth/reset"), this.form)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 200) {
            this.$toast.success(res.data.data);
            this.$router.push("/login");
          }else {
            this.loading = false;
            this.$toast.error(res.data.data);
          }
          
        })
        .catch((err) => {
          

        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>