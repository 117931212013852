<template>
  <div>
    <VueElementLoading
      :active="loading"
      spinner="ring"
      color="#42BFC7"
      text="Loading.."
    />
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div style="color: #43bec7 !important" class="mr-2 mt-2">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="" method="post">
              <VueElementLoading
                :active="loading"
                spinner="ring"
                color="#42BFC7"
                :text="loadingText"
              />
              <div class="row mt-3">
                <div class="col-md-12 mb-3">
                  <label for="topic" style="color: black">API Key</label>
                  <input
                    v-model="form.api.api"
                    type="text"
                    id="topic"
                    cols="30"
                    rows="3"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              data-dismiss="modal"
              style="background: #44bdc7; border: 1px #44bdc7"
              @click.prevent="updateApi()"
            >
              Add Key
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-success fw-400">
      {{ respo }}
    </div>
    <div class="col-9 my-3">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          placeholder="Enter Your Search Engine ID"
          aria-label="Username"
          v-model="form.engine_id"
        />
        <button
          type="button"
          class="btn btn-blue caption-btn mb-3 float-right"
          style="border-radius: 0px 10px 10px 0px !important"
          @click.prevent="updateApi()"
        >
          Update
        </button>
      </div>
    </div>
    <div class="col-9 my-3">
      <button
        type="button"
        class="btn btn-blue caption-btn mb-3 float-right"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Add API Key
      </button>
      <table class="table table-bordered" v-if="apis">
        <tr>
          <th>S/N</th>
          <th>API KEY</th>
          <th>Action</th>
        </tr>
        <tr v-for="(a, i) in apis" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ a.api }}</td>
          <td>
            <button
              class="fa fa-trash"
              style="
                color: red;
                background: none !important;
                border: none !important;
              "
              @click="removeKey(i)"
            ></button>
          </td>
        </tr>
      </table>
      <h1 class="badge badge-info w-100" v-else>No API Key Available</h1>
    </div>
  </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      form: {
        api: {},
      },
      loading: false,

      apis: [],
      respo: "",
    };
  },
  methods: {
    getUserProfile() {
      this.loading = true;
      this.$api
        .get(this.dynamic_route("/users/profile"))
        .then((res) => {
          this.loading = false;
          this.form.username = res.data.username;
          this.form.email = res.data.email;
          this.apis = res.data.api_key;
          this.form.engine_id = res.data.engine_id;
          // console.log();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    updateApi() {
      this.loading = true;
      // this.apis.push(this.api);
      this.$api
        .post(this.dynamic_route("/users/update-api"), this.form)
        .then((res) => {
          this.loading = false;

          this.$toast.success(res.data);
          this.respo =
            "Search Engine ID Updated, Now Add Your API Key Below";
          this.getUserProfile();
        })
        .catch((err) => {
          this.loading = false;

          console.log(err.response);
        })
        .finally(() => {
          this.form.loading = false;
        });
    },
    removeKey(index) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this key!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.loading = true;
          // this.apis.push(this.api);
          this.$api
            .post(this.dynamic_route(`/users/remove-api/${index}`))
            .then((res) => {
              this.loading = false;

              this.$toast.success(res.data);
              this.respo =
                "Congratulations Your API is now activated, you can now use the Golden keyword tool !";
              this.getUserProfile();
            })
            .catch((err) => {
              this.loading = false;

              console.log(err.response);
            })
            .finally(() => {
              this.form.loading = false;
            });
        } else {
        }
      });
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>

<style>
</style>