<template>
  <div>
    <div class="col-12 my-4">
      <VueElementLoading
        :active="loading"
        spinner="ring"
        color="#42BFC7"
        text="Loading.."
      />
      <table class="table border table-bg fw-400">
        <thead style="background-color: #f5f4f4; font-size: 20px">
          <td>Product Name</td>
          <td>Action</td>
        </thead>
        <tbody>
          <tr v-for="(p, i) in packages" :key="i">
            <td>{{ p.name }}</td>
            <td>
              <button
                class="btn btn-blue"
                v-if="!p.users.length"
                @click="getPackage(p)"
              >
                Get This
              </button>
              <button class="btn btn-secondary btn-disabled" v-else disabled>
                &nbsp; &nbsp; ✅ &nbsp; &nbsp;
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      packages: {},
      loading: false,
    };
  },
  methods: {
    getPackages() {
      this.loading = true;
      this.$api
        .get(this.dynamic_route("/packages"))
        .then((res) => {
          this.packages = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPackage(data) {
      this.loading = true;
      let token = JSON.parse(localStorage.getItem("authInfo"))[0].authToken;
      let payload = {
        tablels: token,
        id: data.id,
      };
      this.$api
        .post(this.dynamic_route("/packages"), payload)
        .then((res) => {
          this.$toast.success(res.data.message);
          let authInfo = [
            {
              authToken: res.data.token,
              userData: res.data.data,
              perm: res.data.perm,
              sidebar: res.data.sidebar,
            },
          ];
          console.log(authInfo);
          localStorage.setItem("authInfo", JSON.stringify(authInfo));
          this.getPackages();
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getPackages();
  },
};
</script>