<template>
  <layout>
    <div>
      <!-- ============================================================== -->
      <!-- Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <div class="page-breadcrumb">
        <div class="row align-items-center">
          <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
          <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Bread crumb and right sidebar toggle -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Container fluid  -->
      <!-- ============================================================== -->
      <div class="container-fluid">
        <!-- ============================================================== -->
        <!-- Sales chart -->
        <!-- ============================================================== -->
        <div class="row">
          <!-- Column -->
          <div class="col-12 text-center h1 mb-3 fw-400">
            Keyword <span class="text-blue">Research</span>
          </div>
          <div class="col-12">
            <div class="jumbotron text-center">
               <VueElementLoading
                        :active="loading"
                        spinner="ring"
                        color="#42BFC7"
                        text="Loading.."
                        
                        />
              <div class="input-group mb-3">
                <input
                  type="text"
                  id="search"
                  class="form-control"
                  placeholder="Enter the keyword"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                <select
                  name=""
                  class="select-lang"
                  v-model="lang"
                  :style="'width: 20% !important'"
                >
                  <option value="">Any language</option>
                  <option value="af">Afrikaans</option>
                  <option value="sq">Albanian (Shqip)</option>
                  <option value="sm">Amharic</option>
                  <option value="ar">Arabic (العربية)</option>
                  <option value="az">Azerbaijani (Azərbaycanca)</option>
                  <option value="eu">Basque (Euskal)</option>
                  <option value="be">Belarusian (Беларуская)</option>
                  <option value="bn">Bengali (বাঙ্গালী)</option>
                  <option value="bh">Bihari (বিহারী)</option>
                  <option value="bs">Bosnian (Bosanski)</option>
                  <option value="bg">Bulgarian (Български)</option>
                  <option value="ca">Catalan (Català)</option>
                  <option value="zh-CN">
                    Chinese - Simplified (中国 - 简体)
                  </option>
                  <option value="zh-TW">
                    Chinese - Traditional (中文 - 繁體)
                  </option>
                  <option value="hr">Croatian (Hrvatski)</option>
                  <option value="cs">Czech (Čeština)</option>
                  <option value="da">Danish (Dansk)</option>
                  <option value="nl">Dutch (Nederlands)</option>
                  <option value="en">English</option>
                  <option value="eo">Esperanto</option>
                  <option value="et">Estonian (Eesti)</option>
                  <option value="fo">Faroese (Føroyskt)</option>
                  <option value="fi">Finnish (Suomi)</option>
                  <option value="fr">French (Français)</option>
                  <option value="fy">Frisian</option>
                  <option value="gl">Galician (Galego)</option>
                  <option value="ka">Georgian (ქართული)</option>
                  <option value="de">German (Deutsch)</option>
                  <option value="el">Greek (ελληνικά)</option>
                  <option value="gu">Gujarati (ગુજરાતી)</option>
                  <option value="iw">Hebrew (עברית)</option>
                  <option value="hi">Hindi (हिंदी)</option>
                  <option value="hu">Hungarian (Magyar)</option>
                  <option value="is">Icelandic</option>
                  <option value="id">Indonesian (Bahasa Indonesia)</option>
                  <option value="ia">Interlingua</option>
                  <option value="ga">Irish (Gaeilge)</option>
                  <option value="it">Italian (Italiano)</option>
                  <option value="ja">Japanese (日本語)</option>
                  <option value="jw">Javanese (Jawa)</option>
                  <option value="kn">Kannada (ಕನ್ನಡ)</option>
                  <option value="ko">Korean (한국어)</option>
                  <option value="la">Latin (Latine)</option>
                  <option value="lv">Latvian (Latviešu Valoda)</option>
                  <option value="lt">Lithuanian (Lietuvių Kalba)</option>
                  <option value="mk">Macedonian (Македонски Јазик)</option>
                  <option value="ms">Malay (Bahasa Melayu)</option>
                  <option value="ml">Malayam (മലയാളം)</option>
                  <option value="mt">Maltese (Malti)</option>
                  <option value="mr">Marathi (मराठी)</option>
                  <option value="ne">Nepali (नेपाली)</option>
                  <option value="no">Norwegian (Norsk)</option>
                  <option value="nn">Norwegian (Nynorsk)</option>
                  <option value="oc">Occitan (Lenga d&amp;#039;òc)</option>
                  <option value="fa">Persian (فارسی)</option>
                  <option value="pl">Polish (Polski)</option>
                  <option value="pt-BR">
                    Portuguese - Brazil (Português - Brasil)
                  </option>
                  <option value="pt-PT">
                    Portuguese - Portugal (Português - Portugal)
                  </option>
                  <option value="pa">Punjabi (ਪੰਜਾਬੀ ਦੇ)</option>
                  <option value="ro">Romanian (Român)</option>
                  <option value="ru">Russian (Русский)</option>
                  <option value="gd">Scottish Gaelic (Gàidhlig)</option>
                  <option value="sr">Serbian (Cрпски)</option>
                  <option value="si ">Sinhalese (සිංහල)</option>
                  <option value="sk">Slovak (Slovenský)</option>
                  <option value="sl">Slovenian (Slovenščina)</option>
                  <option value="es">Spanish (Español)</option>
                  <option value="su">Sudanese</option>
                  <option value="sw">Swahili (Kiswahili)</option>
                  <option value="sv">Swedish (Svenska)</option>
                  <option value="tl">Tagalog</option>
                  <option value="ta">Tamil (தமிழ்)</option>
                  <option value="te">Telugu</option>
                  <option value="th">Thai (ภาษาไทย)</option>
                  <option value="ti">Tigrinya (ትግርኛ)</option>
                  <option value="tr">Turkish (Türk)</option>
                  <option value="uk">Ukrainian (Українська)</option>
                  <option value="ur">Urdu (اُردُو‎)</option>
                  <option value="uz">Uzbek (O’zbekcha)</option>
                  <option value="vi">Vietnamese (Việt)</option>
                  <option value="cy">Welsh (Cymraeg)</option>
                  <option value="xh">Xhosa</option>
                  <option value="zu">Zulu</option>
                </select>
                <div class="input-group-append">
                  <button
                    class="btn btn-blue"
                    :style="'border-radius: 0px 10px 10px 0 !important'"
                    @click="startJob(1)"
                    v-if="showBtn"
                  >
                    <span class="fas fa-play"></span> search
                  </button>
                  <button
                    class="btn btn-red"
                    :style="'border-radius: 0px 10px 10px 0 !important'"
                    @click="startJob(0)"
                    v-else
                  >
                    <span class="fas fa-stop"></span> Stop There
                  </button>
                </div>
              </div>
              <div class="row col-xs-12 padding-top" v-if="showAnalytics">
                <!-- <div class="col-xs-12 padding-top">
                  <div class="alert alert-warning text-center" role="alert">
                    <h2 id="keywordtoquery"></h2>
                  </div>
                </div> -->
                <div class="col-12 my-3">
                  <div class="progress progress-striped active">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="well card my-card text-center">
                    <div>Done</div>
                    <h3><span class="label label-warning" id="done"></span></h3>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="well card my-card text-center">
                    To Do<br />
                    <h3><span class="label label-danger" id="to-do"></span></h3>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="well card my-card text-center">
                    Found<br />
                    <h3>
                      <span
                        class="label label-success"
                        id="keywordsCounter"
                      ></span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn-blue" @click="migrateBtn()">Migrate</button> -->
            <div class="row" >
              <!-- <div class="col-md-12">
                <div class="row">
                  <div class="offset-8 col-4">
                    <input class="mt-3 form-control border" type="text" v-model="search" placeholder="Search">
                  </div>
                </div>

              </div> -->
              <div id="result" class="col-md-12">
                <table class="table table-striped dt-responsive nowrap" id="datatable">
                  <thead>
                    <th>ID</th>
                    <th>Keywords</th>
                    <!-- <th>Query</th> -->
                  </thead>
                  <tbody>
                    <!-- <tr v-for="(tdata, i) in newTableData" :key="i">
                      <td>{{ tdata.id }}</td>
                      <td>{{ tdata.result }}</td>
                      <td>{{ tdata.keyword }}</td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- ============================================================== -->
        <!-- Recent blogss -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Container fluid  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- footer -->
      <!-- ============================================================== -->
    </div>
  </layout>
</template>
<script>
import VueElementLoading from 'vue-element-loading'
import layout from "../components/BaseLayouts/Layout.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import $ from "jquery";
export default {
  components: {
    layout,
    VueElementLoading
  },
  data() {
    return {
      toggle: 1,
      lang: "en",
      search: "",
      hashResults: {},
      keywordsCounter: 0,
      toDoWork: false,
      loading : false,
      showBtn: true,
      showAnalytics: false,
      interval: {},
      keywordsQuery: [],
      keywordsQueryIndex: 0,
      queryflag: false,
      valeur: 0,
      compareTable: [],
      newTableData: [
        {id: "1",
        keyword: "digital",
        result: "digital marketing"}
      ],
      dtable : null,
      method: 1,
    };
  },
  methods: {
    startJob(switcher) {
      this.loading = true;
      var $this = this;
      if (switcher) {
       this.$api
        .post(this.dynamic_route("/research-count"))
        .then((res) => {
           this.showBtn = false;
          if (res.status == 200) {
            this.loading = false;
            if (switcher) {
              this.interval = setInterval(function () {
                $this.doJob();
              }, 750);
            } else {
              this.showBtn = true;
              this.toDoWork = false;
              clearInterval(this.interval);
            }
          }else {
            this.$toast.error(res.data.data)
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.showBtn = true;
        this.toDoWork = false;
        clearInterval(this.interval);
      }
      this.showAnalytics = true;
      
      this.toDoWork = false;
      this.queryflag = false;
    },
   migrateBtn() {
    this.$api
        .post(this.dynamic_route("/migrate"), this.current)
        .then((res) => {
        })
   },

    StartJobMethod1() {
      this.method = 1;
      if (this.toDoWork == false) {
        this.hashResults = {};
        this.keywordsCounter = 0;
        this.keywordsQuery = new Array();
        this.keywordsQueryIndex = 0;

        this.hashResults[""] = 1;
        this.hashResults[" "] = 1;
        // this.hashResults["  "] = 1;

        var ks = document.querySelector("#search").val().split("\n");
        var i = 0;
        for (i = 0; i < ks.length; i++) {
          this.keywordsQuery[this.keywordsQuery.length] = ks[i];

          var j = 0;
          for (j = 0; j < 26; j++) {
            var chr = String.fromCharCode(97 + j);
            var currentx = ks[i] + " " + chr;
            this.keywordsQuery[this.keywordsQuery.length] = currentx;
            this.hashResults[currentx] = 1;
          }
        }
        //document.getElementById("input").value = '';
        document.getElementById("search").value += "\r\n";

        this.toDoWork = true;
        // document.querySelector('#startjob').innerHTML =('Stop');
      } else {
        this.toDoWork = false;
        //alert("Do you want to Stop to Find Keywords?");
        // document.querySelector("#startjob").innerHTML =
        //   '<span class="fas fa-stop"></span> Search';
      }
    },
    StartJobMethod2() {
      this.method = 2;
      if (this.toDoWork == false) {
        this.hashResults = {};
        this.keywordsCounter = 0;
        this.keywordsQuery = new Array();
        this.keywordsQueryIndex = 0;

        this.hashResults[""] = 1;
        this.hashResults[" "] = 1;
        // this.hashResults["  "] = 1;

        var ks = document.querySelector("#search").value.split("\n");
        var i = 0;
        for (i = 0; i < ks.length; i++) {
          this.keywordsQuery[this.keywordsQuery.length] = ks[i];

          var j = 0;
          for (j = 0; j < 26; j++) {
            var chr = String.fromCharCode(97 + j);
            var currentx = ks[i] + " " + chr;
            this.keywordsQuery[this.keywordsQuery.length] = currentx;
            this.hashResults[currentx] = 1;
            for (var k = 0; k < 26; k++) {
              var chr = String.fromCharCode(97 + k);
              var currentk = currentx + chr;
              this.keywordsQuery[this.keywordsQuery.length] = currentk;
              this.hashResults[currentk] = 1;
            }
          }
        }
        //document.getElementById("input").value = '';
        document.getElementById("search").value += "\r\n";
        this.toDoWork = true;
      }
      // else {
      //     this.toDoWork = false;
      //     //alert("Do you want to Stop to Find Keywords?");
      //     document.querySelector('#startjob').innerHTML ='Search';
      // }
    },
    doJob() {
      this.StartJobMethod2();
      if (this.toDoWork == true && this.queryflag == false) {
        if (this.keywordsQueryIndex < this.keywordsQuery.length) {
          var currentKw = this.keywordsQuery[this.keywordsQueryIndex];
          this.QueryKeyword(currentKw, this);
          this.keywordsQueryIndex++;
          this.valeur = Math.floor(
            100 * (this.keywordsQueryIndex / this.keywordsQuery.length)
          );
          // console.log(
          //   this.valeur,
          //   this.keywordsQuery.length,
          //   this.keywordsQueryIndex
          // );
          document.querySelector("#to-do").innerHTML =
            this.keywordsQuery.length;
          document.querySelector("#done").innerHTML = this.keywordsQueryIndex;
          document.querySelector(".progress-bar").style.width =
            this.valeur + "%";
          document
            .querySelector(".progress-bar")
            .setAttribute("aria-valuenow", this.valeur);
          document.querySelector(".progress-bar").innerHTML = this.valeur + "%";
        } else {
          if (this.keywordsCounter != 0) {
            alert("Done");
            this.startJob(0)
            this.toDoWork = false;
            // document.querySelector("#startjob").innerHTML = "Start Job";
          } else {
            this.keywordsQueryIndex = 0;
          }
        }
      }
    },
    QueryKeyword(keyword, $this) {
      var querykeyword = keyword;
      //var querykeyword = encodeURIComponent(keyword);
      var queryresult = "";
      this.queryflag = true;
      // document.querySelector("#keywordtoquery").innerHTML = querykeyword;
      var lang = this.lang;
      var newArr = [];
      $.ajax({
        url: "https://suggestqueries.google.com/complete/search",
        jsonp: "jsonp",
        dataType: "jsonp",
        data: {
          q: querykeyword,
          client: "chrome",
          hl: lang,
        },
        success: function (res) {
          var suggestList = res[1];
          var i = 0;
          var sb = "";
          for (i = 0; i < suggestList.length; i++) {
            var currents = $this.CleanValue(suggestList[i]);

            if ($this.hashResults[currents] != 1) {
              $this.hashResults[currents] = 1;
              sb = sb + currents + "\r\n";
              $this.keywordsCounter++;
              var compareRow = [];

              compareRow.push($this.keywordsCounter + "");
              compareRow.push(currents);
              // compareRow.push(querykeyword);
              $this.dtable.fnAddData([$this.keywordsCounter, currents])
              if ($this.method == 1) {
                $this.keywordsQuery[$this.keywordsQuery.length] = currents;
                var j = 0;
                for (j = 0; j < 26; j++) {
                  var chr = String.fromCharCode(97 + j);
                  var currentx = currents + " " + chr;
                  $this.keywordsQuery[$this.keywordsQuery.length] = currentx;
                  $this.hashResults[currentx] = 1;
                }
              }
            }
          }
          
          $this.newTableData.push(...newArr);
          document.querySelector("#keywordsCounter").innerHTML =
            $this.keywordsCounter;

          // document.querySelector("#result").innerHTML =(sb);

          //loader()
          //document.getElementById("input").value += sb;

          //var textarea = document.getElementById("input");
          //textarea.scrollTop = textarea.scrollHeight;
          $this.queryflag = false;
        },
      });
    },
    CleanValue(input) {
      var val = input;
      val = val.replace("\\u003cb\\u003e", "");
      val = val.replace("\\u003c\\/b\\u003e", "");
      val = val.replace("\\u003c\\/b\\u003e", "");
      val = val.replace("\\u003cb\\u003e", "");
      val = val.replace("\\u003c\\/b\\u003e", "");
      val = val.replace("\\u003cb\\u003e", "");
      val = val.replace("\\u003cb\\u003e", "");
      val = val.replace("\\u003c\\/b\\u003e", "");
      val = val.replace("\\u0026amp;", "&");
      val = val.replace("\\u003cb\\u003e", "");
      val = val.replace("\\u0026", "");
      val = val.replace("\\u0026#39;", "'");
      val = val.replace("#39;", "'");
      val = val.replace("\\u003c\\/b\\u003e", "");
      val = val.replace("\\u2013", "2013");
      // console.log(input)
      if (val.length > 4 && val.substring(0, 4) == "http") val = "";
      return val;
    },
  },
  computed: {
    filteredItems () {
      return this.newTableData.filter(item => {
         return item.result.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    }
  },
  updated() {
    // console.log(this.newTableData)
    // this.dtable.fnAddData(this.newTableData);
  },
  mounted(){
    this.dtable = $('#datatable').dataTable( {
            bSort: false,
            dom: 'Bfrtip',
            buttons: ['copy', 'csv', 'print'
            ],
            "aaSorting": [ [0,'asc'], [1,'asc'] ],
            "aoColumns": [
                null,
                null,
            ],
            "pageLength": 30,
            "scrollCollapse": true,
            "info":           true,
            "paging":         true
        } );
  },
  destroyed() {
            this.dtable.destroy();
        }
};

 
</script>
<style scoped>
select {
  width: auto !important;
}
</style>