import Vue from "vue";
import VueRouter from "vue-router";
import Golden from "../views/Golden.vue";
import Content from "../views/Content.vue";
import Rank from "../views/Rank.vue";
import Settings from "../views/Settings.vue";
import Tutorials from "../views/Tutorials.vue";
import Upgrade from "../views/Upgrade.vue";
import Support from "../views/Support.vue";
import Analysis from "../views/Analysis.vue";
import Backlinks from "../views/Backlinks.vue";
import Login from "../views/Auth/Login.vue";
import Register from "../views/Auth/Register.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import Forgot from "../views/Auth/Forgot.vue";
import Keyword from "../views/Keyword.vue";
import Traffic from "../views/Traffic.vue";
import Acl from "../views/Acl.vue";
import ManageUsers from "../views/ManageUsers.vue";
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: Login,
    meta : {
      title : "RankVault - Login"
    }
  },
  {
    path: "/home",
    name: "Keyword",
    component: Keyword,
    meta: { requireAuth: true,  title : "RankVault - Keyword Research" },
  },
  {
    path: "",
    redirect: '/',
  },
  {
    path: "/golden",
    name: "Golden",
    component: Golden,
    meta: { requireAuth: true, title : "RankVault - Golden Keyword" },
  },
  {
    path: "/content",
    name: "Content",
    component: Content,
    meta: { requireAuth: true, title : "RankVault - Create Content" },
  },
  {
    path: "/manage-users",
    name: "Manage Users",
    component: ManageUsers,
    meta: { requireAuth: true },
  },
  {
    path: "/traffic",
    name: "Traffic",
    component: Traffic,
    meta: { requireAuth: true, title : "RankVault - Traffic Vault" },
  },
  {
    path: "/rank",
    name: "Rank",
    component: Rank,
    meta: { requireAuth: true, title : "RankVault - Rank Checker" },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requireAuth: true },
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    component: Tutorials,
    meta: { requireAuth: true, title : "RankVault - Settings" },
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    component: Upgrade,
    meta: { requireAuth: true, title : "RankVault - Package Upgrade" },
  },
  {
    path: "/analysis",
    name: "Analysis",
    component: Analysis,
    meta: { requireAuth: true, title : "RankVault - Website Analysis" },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: { requireAuth: true, title : "RankVault - Support" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register, 
    meta: { title : "RankVault - Register" },
  },
  {
    path: "/reset-password/:token",
    name: "Reset",
    component: ResetPassword, 
    meta: { title : "RankVault - Password Reset" },
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot,
    meta: { title : "RankVault - Forgot Password" },
  },
  {
    path: "/acl",
    name: "ACL",
    component: Acl,
    meta: { title : "RankVault - Acl" },
  },
  {
    path: "/backlinks",
    name: "Backlinks",
    component: Backlinks,
    meta: { title : "RankVault - Backlink" },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    meta : {requireAuth : false, title : "RankVault - Not Found"}
  },
  // {
  //   path: '/401',
  //   name: 'Unauthorized',
  //   component: Unauthorized,
  //   meta : {requireAuth : false}
  // },
  { path: "*", component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
