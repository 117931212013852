<template>
    <div>
        <!-- <div class="preloader">
            <div class="lds-ripple">
                <div class="lds-pos"></div>
                <div class="lds-pos"></div>
            </div>
        </div> -->
        <div
        id="main-wrapper"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="absolute"
        data-header-position="absolute"
        data-boxed-layout="full"
        >
            <appNavbar></appNavbar>
            <appSidebar></appSidebar>
            <div class="page-wrapper">
                <slot />
                <appFooter></appFooter>
            </div>

        </div>
    </div>
</template>
<script>
import appSidebar from './Sidebar.vue';
import appNavbar from './Navbar.vue';
import appFooter from './Footer.vue';
export default {
    components: {appSidebar, appNavbar, appFooter}
}
</script>