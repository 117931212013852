<template>
  <layout>
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="page-breadcrumb">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
        <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <div class="col-12 text-center h1 mb-3 fw-400">Settings</div>
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <nav class="tabs mb-4 align-items-center">
          <button
            class="tabs__item tabs__item_active"
            @click="handleClick('tab1')"
          >
            Personal Info
          </button>
          <!-- active tab -->
          <!-- <button class="tabs__item" @click="handleClick('tab2')">
            Packages
          </button> -->
          <button class="tabs__item" @click="handleClick('tab3')">
            API Keys
          </button>
        </nav>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-10">
          <div class="font-weight-bold" v-if="currentTab == 'tab1'">
          <div>
            <!-- <div class="12 text-dark fw-400">Update your personal details</div> -->
            <div class="row">
              <VueElementLoading
                :active="form.loading"
                spinner="ring"
                color="#42BFC7"
                text="Loading.."
                
              />
              <div class="col-md-1 my-3">
                <label class="fw-400" for="key">Username</label>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Update your username"
                  id="key"
                  v-model="form.username"
                />
              </div>
              <div class="col-md-3"></div>
              <div class="col-md-1 my-3">
                <label class="fw-400" for="key">Email</label>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Update your email"
                  id="key"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="col-3 my-3">
              <button class="btn btn-blue" @click.prevent="updateProfile">
                Update
              </button>
            </div>
            <!-- <div class="col-12 text-success fw-400">
            Congratulations Your API is now activated, you can now use the Golden
            keyword tool !
          </div> -->
          </div>
        </div>
        <!-- <div class="font-weight-bold" v-if="currentTab == 'tab2'">
          <Packages />
        </div> -->

        <div class="font-weight-bold" v-if="currentTab == 'tab3'">
          <APIKeys />
        </div>
        </div>
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
  </layout>
</template>
<style>
.tabs {
  position: relative;
  margin: 0 auto;
}

.tabs__item {
  display: inline-block;
  margin: 0 5px;
  padding: 10px;
  padding-bottom: 8px;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: gray;
  text-decoration: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s;
  width: 20%;
}

.tabs__item_active {
  color: black;
}

.tabs__item:hover {
  /* border-bottom: 2px solid gray; */
  color: black;
}

.tabs__item:focus {
  outline: none;
  /* border-bottom: 2px solid gray; */
  color: white;
  background: #42BFC7 !important;
  border-radius: 10px;
}

.tabs__item:first-child {
  margin-left: 0;
}

.tabs__item:last-child {
  margin-right: 0;
}

.tabs__active-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: black;
  transition: transform 0.4s ease, width 0.4s ease;
}
</style>
<script>
import layout from "../components/BaseLayouts/Layout.vue";
import Tabs from "vue-tabs-with-active-line";
import APIKeys from "./partials/APIKeys.vue";
import Packages from "./partials/Packages.vue";
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    layout,
    Tabs,
    APIKeys,
    Packages,
    VueElementLoading,
  },
  data: () => ({
    tabs: [
      { title: "Tab 1", value: "tab1" },
      { title: "Tab 2", value: "tab2" },
      { title: "Tab 3", value: "tab3" },
    ],
    currentTab: "tab1",
    form: {
      loading: false,
      username: ""
    },
    profile: {},
  }),
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    getUserProfile() {
        this.form.loading = true;
        this.$api
          .get(this.dynamic_route("/users/profile"))
          .then((res) => {
        this.form.loading = false;
            this.form.username = res.data.username;
            this.form.email = res.data.email;
          })
          .catch((err) => {
        this.form.loading = false;
            console.log(err.response);
          })
          .finally(() => {
            // this.loading = false;
          });
    },
    updateProfile() {
      this.form.loading = true;
      this.$api.post(this.dynamic_route("/users/update-profile"), this.form)
        .then((res) => {
          this.form.loading = false;

          this.$toast.success(res.data);
          this.getUserProfile();
        })
        .catch((err) => {
          this.form.loading = false;

          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
