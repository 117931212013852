<template>
    <div>
        <!-- ============================================================== -->
        <!-- Preloader - style you can find in spinners.css -->
        <!-- ============================================================== -->
        <!-- <div class="preloader">
            <div class="lds-ripple">
                <div class="lds-pos"></div>
                <div class="lds-pos"></div>
            </div>
        </div> -->
    
        <div class="page-wrapper">
            <div class="wrapper">
                <div class="inner-div row">
                    <div class="col-lg-6 col-md-6 col-12 bg-light-grey d-none d-md-block d-lg-block"  style="height:100% ;">
                        <img src="assets/images/Rankvault.png" alt="" class="w-100" style="height:100% ;">
                    </div>
                    <div class="col-lg-6 col-md-6 col-12 bg-light h-100 p-md-5 p-3">
                        <div class="text-center text-blue mb-5">
                            <span class="fas fa-user-circle fa-3x"></span>
                            <p class="upper-text my-2 ">Member Login</p>
                        </div>
                        <form action="" @submit.prevent="login()">
                        <VueElementLoading
                        :active="loading"
                        spinner="ring"
                        color="#42BFC7"
                        text="Loading.."
                        
                        />
                            <div class="row">
                                <div class="col-12">
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text px-4" id="basic-addon1">
                                            <span class="far fa-user-circle fa-2x"></span>
                                        </span>
                                        </div>
                                        <input type="email" class="form-control" v-model="form.email" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                        <span class="input-group-text px-4" id="basic-addon1">
                                            <span class="fas fa-lock fa-2x"></span>
                                        </span>
                                        </div>
                                        <input type="password" class="form-control" v-model="form.password" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-blue btn-block w-100">LOGIN</button>
                                </div>
                                <!-- <div class="col-7 mt-2" v-if="1==1">
                                    don't have an account? <router-link to="/register"  class="forgot-text">Register</router-link>
                                </div> -->
                                <div class="col-5 mt-2" style="text-align:right ;">
                                    <router-link to="/forgot" class="forgot-text" >
                                        Forgot Password?
                                    </router-link>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>


<style>
    .wrapper{
        height: 100vh;
        background-color: rgb(241, 248, 248);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    body{
        margin: 0;
        padding: 0;
    }
    .inner-div{
        min-height: 60%;
        width: 70%;
        background-color: #fff;
        margin: auto auto;
        box-shadow: 1px 1px 10px rgb(178, 178, 178); 
    }
    .bg-light-grey{
        background-color: #eeeeee;
    }
    .upper-text{
        font-size: 18px;
    }
    .forgot-text{
        font-size: 18px;
        text-decoration: none !important;
        color: rgb(92, 88, 88);
        margin-top: 5px;
    }
    .form-control, .input-group-text, .form-control:focus{
        background-color: #eeeeee;
    }
    .input-group-text{
        border-right: 1px solid lightgrey !important;
        border-radius: 10px 0px 0px 10px;
    }
    .input-group-prepend{
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        border-right: 1px solid #eeeeee;
    }
    .btn{
        border-radius: 10px !important;
    }
    .fa-2x{
        font-size: 18px !important;
        padding: 5px;
    }
    @media (max-width:992px){
        .inner-div {
            height: 50%;
            width: 85%;
            background-color: #fff;
            margin: auto auto;
            box-shadow: 1px 1px 10px rgb(178 178 178);
        }
    }
</style>
<script>
import axios from 'axios'
import VueElementLoading from "vue-element-loading"
export default {
    data() {
        return {
            form: {},
            loading:false
        }
    },
    components: {
        VueElementLoading
    },
    methods: {
        getLocation() {
            
        },
        login() {
            if(this.form.email && this.form.password) {
                this.loading = true;
                axios.post(this.dynamic_route("/auth/login"), this.form)
                .then((res) => {
                    this.loading = false;
                    this.$toast.success("Authenticated");
                    let authInfo = [{
                        authToken : res.data.token,
                        userData: res.data.data,
                        perm: res.data.perm,
                        sidebar: res.data.sidebar
                        }];
                        console.log(authInfo);
                        localStorage.setItem('authInfo', JSON.stringify(authInfo));
                        window.location.href = "/home"
                        // this.$router.push({name: 'Keyword'})
                })
                .catch((err) => {
                    this.loading = false;
                    this.$toast.error(err.response.data.error)
                });
            } else {
                    this.$toast.error("All fields are required")

            }
            },
  }
}
</script>
