export default {
  data: () => {
    return {};
  },
  methods: {
    dynamic_route(endUrl) {
      // console.log(process.env.VUE_APP_BASE_URL);
      // return process.env.VUE_APP_BASE_URL + endUrl;
      let status = 'production';
      if (process.env.VUE_APP_ENV == 'local') {
        // console.log('local')
        return "http://rankvault_backend.test/api" + endUrl;
      } else{
        // console.log('online')
        return process.env.VUE_APP_BASE_URL + endUrl;
      }
      
      
    },
    //   dynamic_auth_route(endUrl) {
    //     return process.env.VUE_APP_BASE_AUTH_URL+endUrl
    //   },
    //   logoutUser() {
    //     this.$toast.error('Login expired, please login again!', {
    //       position: 'top-center',
    //       timeout: 5000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: true,
    //       closeButton: 'button',
    //       icon: true,
    //       rtl: false,
    //     })
    //     localStorage.removeItem('auth_token');
    //     localStorage.removeItem('auth_user');
    //     return this.$router.push({name: 'Login'});
    //   }
  },
};
