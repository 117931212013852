<template>
  <layout>
    <div class="page-breadcrumb">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
        <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
      </div>
    </div>

    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <div class="col-12 text-center h1 mb-3 fw-400">
          Google Rank <span class="text-blue">Checker</span>
        </div>
        <p class="text-center text-dark">
          Google rank checker helps you find your top traffic-driving keywords.
        </p>
        <div class="col-12">
          <VueElementLoading
            :active="loading"
            spinner="ring"
            color="#42BFC7"
            text="Loading.."
          />
          <div class="jumbotron text-center">
            <form @submit.prevent="getWebRanks">
              <div class="row">
                <div class="12 text-dark">
                  Enter your domain to to identify high volume keywords that you
                  can easily boost to page one !
                </div>
                <div class="col-9 my-3">
                  <div class="input-group mb-3">
                    <input
                      v-model="form.hostname"
                      type="text"
                      required
                      class="form-control"
                      placeholder="Enter website address"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="col-3 my-3">
                  <button class="btn btn-block btn-blue">
                    View Google Ranking
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 my-4">
          <section class="tool" v-if="data">
            <div class="advertising">
              <div class="content">
                <div class="table-container">
                  <h2>Top Search Queries</h2>

                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th width="50px"></th>
                        <th>Keyword</th>
                        <th class="center" width="120px">Rank</th>
                        <th class="center" width="120px">Volume</th>
                        <th class="center" width="120px">CPC</th>
                        <th class="center" width="120px">Difficulty</th>
                        <th class="center" width="120px">Clicks</th>
                        <th class="center" width="120px">Homepages</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(d, i) in data" :key="i">
                        <td>
                          <img
                            class="flag"
                            :src="`https://bailey.sh/demo/seostudio/resources/images/countries/${d.countryCode}.svg`"
                          />
                        </td>
                        <td>
                          <div class="ellipsis">{{ d.keyword }}</div>
                        </td>
                        <td class="center">{{ d.rank }}</td>
                        <td class="center">{{ d.searchVolume }}</td>
                        <td class="center">
                          {{ d.exactCostPerClick ? d.exactCostPerClick : "-" }}
                        </td>
                        <td class="center">
                          <div class="colored-value">
                            <div class="value">{{ d.rankingDifficulty }}</div>
                            <div
                              class="circle"
                              :class="{
                                good: d.rankingDifficulty < 50,
                                bad: d.rankingDifficulty >= 50,
                              }"
                            ></div>
                          </div>
                        </td>
                        <td class="center">{{ d.seoClicks }}</td>
                        <td class="center">{{ d.rankingHomepages }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="slots"></div>
            </div>
          </section>
        </div>
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
  </layout>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import layout from "../components/BaseLayouts/Layout.vue";
export default {
  components: {
    layout,
    VueElementLoading,
  },
  data() {
    return {
      data: false,
      form: {},
      loading: false,
    };
  },
  methods: {
    getWebRanks() {
      this.loading = true;
      this.$api
        .post(this.dynamic_route("/check-ranking"), this.form)
        .then((res) => {
          this.data = res.data;
        })
        .catch((err) => {
          this.form.loading = false;

          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
section.tool img.flag {
  border-radius: 3px;
  width: 26px;
}

img {
  vertical-align: middle;
}
img {
  border: 0;
}
section.tool .colored-value .circle {
  width: 11px;
  height: 11px;
  border-radius: 11px;
}
section.tool .colored-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
}
section.tool {
  padding: 40px 20px 0;
}
section.tool .table-container {
  margin: 0 0 25px;
  background-color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  overflow-x: auto;
}
.good {
  background: #7eb85d;
}
.bad {
  background: #e85f3f;
}
</style>