<template>
  <div>
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <header class="topbar top-bar" data-navbarbg="skin6">
      <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <div class="navbar-header logo-area" data-logobg="skin6">
          <!-- ============================================================== -->
          <!-- Logo -->
          <!-- ============================================================== -->
          <router-link class="navbar-brand" to="/home">
            <!-- Logo icon -->
            <b class="logo-icon">
              <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
              <!-- Dark Logo icon -->
              <!-- <img
                  src="../assets/images/logo-icon.png"
                  alt="homepage"
                  class="dark-logo"
                /> -->
            </b>
            <!--End Logo icon -->
            <!-- Logo text -->
            <span
              class="logo-text text-white font-weight-bold"
              style="font-weight: 500; font-size: 20px"
            >
              <!-- dark Logo text -->
              RANKVAULT
            </span>
          </router-link>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- toggle and nav items -->
          <!-- ============================================================== -->
          <a
            class="nav-toggler waves-effect waves-light text-dark d-block d-md-none"
            href="javascript:void(0)"
            ><i class="ti-menu ti-close"></i
          ></a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div
          class="navbar-collapse collapse"
          id="navbarSupportedContent"
          data-navbarbg="skin5"
        >
          <!-- ============================================================== -->
          <!-- toggle and nav items -->
          <!-- ============================================================== -->
          <ul class="navbar-nav me-auto mt-md-0">
            <!-- ============================================================== -->
            <!-- Search -->
            <!-- ============================================================== -->

            <li class="nav-item hidden-sm-down">
              <!-- <form class="app-search ps-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search for..."
                  />
                  <a class="srh-btn"><i class="ti-search"></i></a>
                </form> -->
            </li>
          </ul>

          <!-- ============================================================== -->
          <!-- Right side toggle and nav items -->
          <!-- ============================================================== -->
          <ul class="navbar-nav">
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <li class="nav-item dropdown">
              <span
                  class="nav-link dropdown-toggle waves-effect waves-dark"
              
                  id="navbarDropdown"
                  role="button"
                  aria-expanded="false"
                  @click="logout()"
                >
                <i class="me-3  fas fa-sign-out-alt" aria-hidden="true"></i>Logout
                </span>
              <ul
                class="dropdown-menu show"
                aria-labelledby="navbarDropdown"
              ></ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- ============================================================== -->
    <!-- End Topbar header -->
  </div>
</template>
<script>
export default {
  methods:{
    logout(){
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>