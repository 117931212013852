<template>
  <layout>
    <!-- Bread crumb and right sidebar toggle -->
    <!-- ============================================================== -->
    <div class="page-breadcrumb">
      <div class="row align-items-center">
        <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div style="color: #43bec7 !important" class="mr-2 mt-2">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="" method="post">
              <VueElementLoading
                :active="loading"
                spinner="ring"
                color="#42BFC7"
                text="Please wait..."
              />
              <div class="row mt-3">
                <div class="col-md-12 mb-3">
                  <label for="variant" style="color: black"
                    >Username</label
                  >
                  <div class="input-group">
                    
                    <input type="text" name="" class="form-control" v-model="current.username" id="">
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="variant" style="color: black"
                    >Email</label
                  >
                  <div class="input-group">
                    
                    <input type="email" name="" class="form-control" v-model="current.email" id="">
                  </div>
                </div>


                <div class="col-md-12 mb-3">
                  <label for="variant" style="color: black"
                    >Engine ID</label
                  >
                  <div class="input-group">
                    
                    <input type="text" name="" class="form-control" v-model="current.engine_id" id="">
                  </div>
                </div>
                
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
                    type="button"
                    class="btn btn-primary"
                    style="background:#44bdc7; border:1px #44bdc7;"
                    
                    @click.prevent="updateuser()"
                  >
                    Update
                  </button>
          </div>
        </div>
      </div>
    </div>
        <!-- <div class="col-md-6 col-8 align-self-center">
              <h3 class="page-title mb-0 p-0">Dashboard</h3>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div> -->
        <!-- <div class="col-md-6 col-4 align-self-center">
                        <div class="text-end upgrade-btn">
                            <a href="https://www.wrappixel.com/templates/monsteradmin/"
                                class="btn btn-success d-none d-md-inline-block text-white" target="_blank">Upgrade to
                                Pro</a>
                        </div>
                    </div> -->
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- End Bread crumb and right sidebar toggle -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales chart -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->
        <div class="col-12 text-center h1 mb-3 fw-400">Manage Users</div>

        <div class="col-md-12">
          <button class="btn btn-blue mb-2 float-right" @click="createuser()">
            Create User
          </button>
          <table class="table table-responsive table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Email</th>
                <th>API Key</th>
                <th>Engine ID</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(u, i) in users.data" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ u.username }}</td>
                <td>{{ u.email }}</td>
                <td>
                  <span v-for="(k,i) in u.api_key" :key="i">{{ k.api }} <br></span>
                </td>
                <td>{{ u.engine_id }}</td>
                <td>
                  <div class="dropdown">
                    <div
                      class=" dropdown-toggle"
                      type="button"
                      
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa fa-th" aria-hidden="true"></i>
                  </div>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" type="button" @click="getUser(u)" data-toggle="modal"
            data-target="#exampleModal">Edit</a>
                      <!-- <a class="dropdown-item" href="#">Login as</a> -->
                      <!-- <a class="dropdown-item" href="#">Delete</a> -->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- ============================================================== -->
      <!-- Recent blogss -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
  </layout>
</template>

<script>
import layout from "../components/BaseLayouts/Layout.vue";
import Tabs from "vue-tabs-with-active-line";
import VueElementLoading from "vue-element-loading";

export default {
  components: {
    layout,
    Tabs,
    VueElementLoading,
  },
  data: () => ({
    form: {
      loading: false,
    },
    loading:false,
    users: {},
    current: {}
  }),
  methods: {
    getUserProfile() {
      this.form.loading = true;
      this.$api
        .get(this.dynamic_route("/users/get_paginated_users"))
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {
          this.form.loading = false;
          console.log(err.response);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    createuser() {
      this.$router.push("/register");
    },
    getUser(data) {
        this.current = data
    },
    updateuser() {
      this.loading = true;
      this.$api
        .post(this.dynamic_route("/users/update-profile"), this.current)
        .then((res) => {
          this.loading = false;
          this.$toast.success(res.data);
          $('#exampleModal').modal('hide');
          this.getUserProfile();
        })
        .catch((err) => {
          this.loading = false;

          console.log(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUserProfile();
  },
};
</script>
