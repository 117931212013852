<template>
  <div>
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" data-sidebarbg="skin6">
      <!-- Sidebar scroll-->
      <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <div class="user-pix position-relative">
          <div class="online"></div>
          <img
            src="assets/images/users/1.jpg"
            alt="user"
            class="user-image"
          />
         <div class="text-center mt-2">
         WELCOME {{user_data.username.toUpperCase()}},
        </div>
        </div>
        
        <nav class="sidebar-nav">
          <ul id="sidebarnav">
            <!-- User Profile-->
            <li class="sidebar-item" v-for="(m,i) in menu" :key="i">
              <router-link
                class="sidebar-link waves-effect waves-dark sidebar-link"
                :to="m.to"
                aria-expanded="false"
                ><i :class="m.icon" aria-hidden="true"></i
                ><span class="hide-menu">{{ m.title }}</span></router-link
              >
            </li>
            <li class="sidebar-item">
              <router-link
                class="sidebar-link waves-effect waves-dark sidebar-link"
                :to="'/backlinks'"
                aria-expanded="false"
                ><i class="me-3 fa fa-bolt" aria-hidden="true"></i
                ><span class="hide-menu">Back Links</span></router-link
              >
            </li>
          </ul>
        </nav>
        <!-- End Sidebar navigation -->
      </div>
      <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  </div>
</template>
<script>
export default {
  data(){
    return {
      user_data : JSON.parse(localStorage.getItem('authInfo'))[0].userData,
      // menu : ,
      menu: []
    }
  },
  methods: {
    getMenu() {
        // console.log(JSON.parse(localStorage.getItem('authInfo'))[0].sidebar);
        this.menu = JSON.parse(localStorage.getItem('authInfo'))[0].sidebar;
      
    },
  },
  mounted() {
    this.getMenu();
  }
}
</script>

<style>
 a{
   text-decoration: none !important;
 }
</style>
