<template>
  <div>
     <router-view></router-view>
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
<script>
// import layout from "./components/BaseLayouts/Layout.vue"
export default {
    // components: {
    //   layout
    // }
}
</script>
